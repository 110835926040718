<template>
  <div class="">
    <form autocomplete="off">
      <div class="row"></div>

      <div class="my-card">
        <div class="row">
          <template>
            <CustomInput
              :className="'col-md-6'"
              :id="`${id}-userNameAr`"
              :errors="errors_userNameAr"
              :value="user.userNameAr"
              v-on:changeValue="
                user.userNameAr = $event;
                $v.user.userNameAr.$touch();
              "
              :title="$t('Users.nameAr')"
              :imgName="'users.svg'"
              :disabledStatus="disabledStatus"
            />
            <CustomInput
              :className="'col-md-6'"
              :id="`${id}-userNameEn`"
              :errors="errors_userNameEn"
              :value="user.userNameEn"
              v-on:changeValue="
                user.userNameEn = $event;
                $v.user.userNameEn.$touch();
              "
              :title="$t('Users.nameEn')"
              :imgName="'users.svg'"
              :disabledStatus="disabledStatus"
            />
            <CustomPhonePicker
              :className="'col-md-6'"
              :id="`${id}-phone`"
              :countryCodeName="user.userPhoneCountryCodeName"
              :countryCode="user.userPhoneCountryCode"
              :phoneNumber="user.userPhone"
              :title="$t('Users.phone')"
              :imgName="'phone.svg'"
              v-on:changeValue="setPhone($event)"
            />
            <CustomSelectBox
              v-if="userTypeToken == USER_TYPE.Employee"
              :className="'col-md-6'"
              :id="`${id}-userActivityTypeToken`"
              :value="user.userActivityTypeToken"
              :options="userActivityTypeTokenOptions"
              v-on:changeValue="user.userActivityTypeToken = $event"
              :title="$t('Users.activityType')"
              :imgName="'activity-types.svg'"
            />
            <CustomSelectBox
              :className="'col-md-6'"
              :id="`${id}-establishmentRoleToken`"
              :value="
                !disabledStatus
                  ? user.establishmentRoleToken
                  : user.establishmentRoleData.establishmentRoleNameCurrent
              "
              :options="establishmentRoleTokenOptions"
              v-on:changeValue="user.establishmentRoleToken = $event"
              :title="$t('EstablishmentRoles.select')"
              :imgName="'type.svg'"
              :disabledStatus="disabledStatus"
            />
          </template>

          <template>
            <CustomInputPassword
              v-if="submitName != $t('edit')"
              :className="'col-md-6'"
              :id="`${id}-userPassword`"
              :value="user.userPassword"
              v-on:changeValue="user.userPassword = $event"
              :title="$t('Users.password')"
              :imgName="'passwordlock.svg'"
            />

            <BaseTree
              :hierarchySystemTypeFilterToken="hierarchySystemTypeJobGradeToken"
              :treeModel="getTreeModelJobGrade"
              v-on:changeValue="baseTreeChanged($event)"
              :showParent="true"
              :filterParentDirect="true"
            />
          </template>
        </div>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
      <!-- ================ added by Magdy ================ -->
      <!-- <div class="select-group-input-container">
        <label class="mx-2" for="chose-group-id">{{ $t("choseGroup") }}</label>
        <select
          v-model="selectedGroup"
          id="chose-group-id"
          class="select-group-input"
        >
          <option selected disabled>{{ $t("selectBox.selectLabel") }}</option>
          <option
            v-for="group in allEduGroups"
            :value="group.itemToken"
            :key="group.itemToken"
          >
            <div class="select-group-option">
              <span> {{ group.itemName }}</span>
            </div>
          </option>
        </select>
      </div> -->
      <!-- ================ added by Magdy ================ -->
    </form>
    <!-- ===================== -->
    <!-- <button class="btn btn-danger" @click="getAllEguGroups()">
      getAllEguGroups
    </button> -->
    <!-- <CustomSelectBox
      :className="'col-md-6'"
      :id="`${id}-selectGroup`"
      :value="
        !disabledStatus
          ? user.establishmentRoleToken
          : user.establishmentRoleData.establishmentRoleNameCurrent
      "
      :options="allEduGroups"
      v-on:changeValue="user.establishmentRoleToken = $event"
      :title="group"
      :imgName="'type.svg'"
      :disabledStatus="disabledStatus"
    /> -->
    <!-- <label class="label" :for="id">{{ title ? title : $t("notFound") }}</label>
    <multiselect
      v-model="selectedGroup"
      :placeholder="title ? title : $t('notFound')"
      label="text"
      track-by="value"
      :options="allEduGroups"
      :option-height="104"
      :show-labels="true"
      :selectedLabel="$t('selectBox.selectedLabel')"
      :selectLabel="$t('selectBox.selectLabel')"
      :deselectLabel="$t('selectBox.deselectLabel')"
      :multiple="false"
      :close-on-select="true"
      :searchable="true"
    >
      <template slot="singleLabel">
        <div class="image"></div>
        <label class="label" :for="id">{{
          title ? title : $t("notFound")
        }}</label>
        <div class="select-box-container">
          <span class="option__desc">
            <span class="option__title text-dark">{{
              allEduGroups.itemName
            }}</span>
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props"
        ><img
          v-if="props.option.image"
          class="option__image"
          :src="props.option.image"
          :onerror="`this.src='${require('@/assets/images/' + imgName)}'`"
        />
        <div class="option__desc">
          <span class="option__title">{{ allEduGroups.itemName }}</span>
        </div>
      </template>
      <template slot="noOptions">
        {{ $t("selectBox.noOptions") }}
      </template>
      <template slot="noResult">
        {{ $t("selectBox.noElementsFoundOfThisSearch") }}
      </template>
    </multiselect> -->

    <!-- =========================== -->
  </div>
</template>

<script>
import axios from "axios";
// import CustomSelectInputMag from "@/general/CustomSelectInputMag.vue";

import {
  getLanguage,
  fullPathFileFromServer,
  bottomSheetScrollToTop,
} from "./../../utils/functions";
import {
  isValidUserNameAr,
  isValidUserNameEn,
  isValidEmail,
  // isValidTextAr,
  // isValidTextEn,
} from "./../../utils/validationHelper";
// import CustomFileInput from "./../../components/general/CustomFileInput.vue";
import BaseTree from "./../../components/general/BaseTree.vue";
import CustomInput from "./../../components/general/CustomInput.vue";
import CustomSelectBox from "./../../components/general/CustomSelectBox.vue";
// import CustomCheckbox from "./../../components/general/CustomCheckbox.vue";
// import TextArea from "./../../components/general/TextArea.vue";
// import DateTimePicker from "./../../components/general/DateTimePicker.vue";
import generalMixin from "./../../utils/generalMixin";
import { STATUS, BASE_URL } from "./../../utils/constants";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CustomInputPassword from "@/components/general/CustomInputPassword.vue";
import CustomPhonePicker from "@/components/general/CustomPhonePicker.vue";
import JobGrades from "@/models/settings/settingsOthers/jobGrades/JobGrades";
import apiJobGrade from "./../../api/jobGrade";
import JobGradeImg from "@/assets/images/jobGrade.svg";
import {
  getDialogOfCitizenshipTypes,
  getDialogOfDeathTypes,
  getDialogOfGender,
  getDialogOfUserActivityType,
} from "./../../utils/dialogsOfConstantsLists";
import { SYSTEM_TYPE, USER_TYPE } from "./../../utils/constantLists";
import {
  getEstablishmentRolesDialog,
  getMilitaryStatusTypesDialog,
  getEducationalStagesDialog,
  getSpecialtiesDialog,
  getCountriesDialog,
  getSocialStatusesDialog,
  getInsuranceCompaniesDialog,
  getBanksDialog,
  getReligionsDialog,
} from "./../../utils/dialogsOfApi";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    // CustomFileInput,
    // Multiselect,
    // CustomSelectInputMag,
    BaseTree,
    CustomInputPassword,
    CustomPhonePicker,
    CustomInput,
    CustomSelectBox,
    // CustomCheckbox,
    // TextArea,
    // DateTimePicker,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: getLanguage(),
      establishmentRoleTokenOptions: [],
      militaryStatusTypeTokenOptions: [],
      educationalStageTokenOptions: [],
      specialtieTokenOptions: [],
      nationalityCountryTokenOptions: [],
      jobGradeTokenOptions: [],
      userCitizenshipTypeTokenOptions: getDialogOfCitizenshipTypes(),
      deathTypeTokenOptions: getDialogOfDeathTypes(),
      userGenderTokenOptions: getDialogOfGender(),
      userActivityTypeTokenOptions: getDialogOfUserActivityType(),
      socialStatuseTokenOptions: [],
      insuranceCompanyTokenOptions: [],
      bankTokenOptions: [],
      jobGrades: new JobGrades(),
      religionTokenOptions: [],
      USER_TYPE,
      // ==========
      allEduGroups: [],
      userAuthorizeToken: "",
      selectedGroup: null,
    };
  },
  props: {
    user: {
      type: Object,
    },
    userTypeToken: {
      type: String,
      default: "",
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    user: {
      userNameAr: { required, isValidUserNameAr },
      userNameEn: { required, isValidUserNameEn },
      userEmail: { isValidEmail },
    },
  },
  computed: {
    className() {
      let className = "";
      if (this.submitName == this.$t("add")) {
        if (this.userTypeToken == USER_TYPE.Employee) {
          className = "col-md-6";
        } else {
          className = "col-md-12";
        }
      } else {
        if (this.userTypeToken == USER_TYPE.Employee) {
          className = "col-md-12";
        } else {
          className = "col-md-6";
        }
      }
      return className;
    },
    hierarchySystemTypeJobGradeToken() {
      return SYSTEM_TYPE.JobGrade;
    },
    getTreeModelJobGrade() {
      let treeModelObject = {
        systemComponentHierarchyToken:
          this.user.userProfileData.jobGradeData.systemComponentHierarchyToken,
        hierarchyTitle: this.$t("SystemComponentsHierarchies.selectJobGrade"),
        systemComponentToken:
          this.user.userProfileData.jobGradeData.systemComponentToken,
        componentTitle: this.$t("SystemComponents.selectJobGrade"),
        parentToken: this.user.userProfileData.jobGradeData.jobGradeToken,
        parentTitle: this.$t("JobGrades.select"),
        parentImgName: "jobGrade.svg",
      };
      return treeModelObject;
    },
    hierarchySystemTypeFilterToken() {
      return SYSTEM_TYPE.GeographicalDistribution;
    },
    getTreeModel() {
      let treeModelObject = {
        systemComponentHierarchyToken:
          this.user.userProfileData.geographicalDistributionData
            .systemComponentHierarchyToken,
        hierarchyTitle: this.$t(
          "SystemComponentsHierarchies.selectGeographicalDistribution"
        ),
        systemComponentToken:
          this.user.userProfileData.geographicalDistributionData
            .systemComponentToken,
        componentTitle: this.$t(
          "SystemComponents.selectGeographicalDistribution"
        ),
        parentToken: this.user.userProfileData.geographicalDistributionToken,
        parentTitle: this.$t("GeographicalDistributions.select"),
        parentImgName: "geographicalDistributions.svg",
      };
      return treeModelObject;
    },

    errors_userNameAr() {
      let errors = [];
      if (this.$v.user.userNameAr.$error) {
        if (
          !this.$v.user.userNameAr.required &&
          !this.$v.user.userNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.user.userNameAr.required &&
          !this.$v.user.userNameAr.isValidUserNameAr
        )
          errors.push(this.$t("validation.userNameIsRequiredInAr"));
      }
      return errors;
    },
    errors_userNameEn() {
      let errors = [];
      if (this.$v.user.userNameEn.$error) {
        if (
          !this.$v.user.userNameAr.required &&
          !this.$v.user.userNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.user.userNameEn.required &&
          !this.$v.user.userNameEn.isValidUserNameEn
        )
          errors.push(this.$t("validation.userNameIsRequiredInEn"));
      }
      return errors;
    },
    errors_userEmail() {
      let errors = [];
      if (this.$v.user.userEmail.$error) {
        if (!this.$v.user.userEmail.isValidEmail)
          errors.push(this.$t("validation.fieldMustBeEmail"));
      }
      return errors;
    },
  },
  mounted() {
    this.getAllEguGroups();
  },
  methods: {
    // ======================= magdy added

    async getAllEguGroups() {
      const groupUrl = `${BASE_URL}/api/EducationalGroups/GetEducationalGroupDialog?activationTypeTokens=AST-17400&textSearch=&establishmentToken=&creationDateTimeFrom=&creationDateTimeTo=&lastUpdateDateTimeFrom=&lastUpdateDateTimeTo=&lastArchiveDateTimeFrom=&lastArchiveDateTimeTo=&lastRestoreDateTimeFrom=&lastRestoreDateTimeTo=&creationDateTime=&lastUpdateDateTime=&lastArchiveDateTime=&lastRestoreDateTime=&createdByUserToken=&lastUpdatedByUserToken=&lastArchivedByUserToken=&lastRestoredByUserToken=&itemName=&educationalPeriodToken=&educationalGroupToken=&educationalGroupStartDateTimeFrom=&educationalGroupStartDateTimeTo=&educationalGroupEndDateTimeFrom=&educationalGroupEndDateTimeTo=&educationalCategoryInfoDataInclude=true&educationalPeriodDataInclude=true&educationalGroupWeekDayDataInclude=true&studentUserToken=`;
      try {
        const response = await axios.get(groupUrl, {
          headers: {
            userAuthorizeToken: this.userAuthorizeToken,
          },
        });

        this.allEduGroups = response.data.itemsData;
        // console.log(response.data.itemsData);
        console.log(this.allEduGroups);
      } catch (error) {
        console.error(error);
      }
    },
    // =======================
    baseTreeChanged(data) {
      if (data.systemTypeToken == SYSTEM_TYPE.JobGrade) {
        this.user.userProfileData.jobGradeData.systemComponentHierarchyToken =
          data.hierarchyToken;
        this.user.userProfileData.jobGradeData.systemComponentToken =
          data.componentToken;
        this.user.userProfileData.jobGradeToken = data.parentToken;
      }
      if (data.systemTypeToken == SYSTEM_TYPE.GeographicalDistribution) {
        this.user.userProfileData.geographicalDistributionData.systemComponentHierarchyToken =
          data.hierarchyToken;
        this.user.userProfileData.geographicalDistributionData.systemComponentToken =
          data.componentToken;
        this.user.userProfileData.geographicalDistributionToken =
          data.parentToken;
      }
    },

    setPhone(data) {
      this.user.userPhoneCountryCodeName = data.countryCodeName;
      this.user.userPhoneCountryCode = data.countryCode;
      this.user.userPhone = data.phoneNumber;
    },

    async getEstablishmentRoleDialog() {
      let modelName = "";
      switch (this.userTypeToken) {
        case USER_TYPE.AdminsEgypt:
          modelName = "adminsEgyptEstablishmentsRole";
          break;
        case USER_TYPE.MasterAdmin:
          modelName = "masterAdminEstablishmentsRole";
          break;
        case USER_TYPE.Employee:
          modelName = "employeeEstablishmentsRole";
          break;
        case USER_TYPE.Student:
          modelName = "studentEstablishmentsRole";
          break;
        case USER_TYPE.Supplier:
          modelName = "supplierEstablishmentsRole";
          break;
        case USER_TYPE.Parent:
          modelName = "parentEstablishmentsRole";
          break;
        case USER_TYPE.Client:
          modelName = "clientEstablishmentsRole";
          break;

        default:
          break;
      }

      this.isLoading = true;
      this.establishmentRoleTokenOptions = await getEstablishmentRolesDialog(
        modelName
      );
      this.isLoading = false;
    },
    async getMilitaryStatusTypesDialog() {
      this.isLoading = true;
      this.militaryStatusTypeTokenOptions =
        await getMilitaryStatusTypesDialog();
      this.isLoading = false;
    },
    async getEducationalStagesDialog() {
      this.isLoading = true;
      this.educationalStageTokenOptions = await getEducationalStagesDialog();
      this.isLoading = false;
    },
    async getSpecialtiesDialog() {
      this.isLoading = true;
      this.specialtieTokenOptions = await getSpecialtiesDialog();
      this.isLoading = false;
    },

    async getNationalityDialog() {
      this.isLoading = true;
      this.nationalityCountryTokenOptions = await getCountriesDialog();
      this.isLoading = false;
    },
    async getJobGradeTokenDialog() {
      this.isLoading = true;
      let options = [];
      try {
        const response = await apiJobGrade.getDialog(this.jobGrades.filterData);
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            options.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
              image: itemsData[item]["itemImagePath"]
                ? BASE_URL + itemsData[item]["itemImagePath"]
                : JobGradeImg,
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          options.push({
            value: "",
            text: response.data.msg,
            image: JobGradeImg,
          });
        }
      } catch (error) {
        options.push({
          value: "",
          text: this.$t("errorCatch"),
          image: JobGradeImg,
        });
      }
      this.jobGradeTokenOptions = options;
      this.isLoading = false;
    },
    async getSocialStatuseDialog() {
      this.isLoading = true;
      this.socialStatuseTokenOptions = await getSocialStatusesDialog();
      this.isLoading = false;
    },
    async getInsuranceCompanyDialog() {
      this.isLoading = true;
      this.insuranceCompanyTokenOptions = await getInsuranceCompaniesDialog();
      this.isLoading = false;
    },
    async getBankDialog() {
      this.isLoading = true;
      this.bankTokenOptions = await getBanksDialog();
      this.isLoading = false;
    },
    async getReligionsDialog() {
      this.isLoading = true;
      this.religionTokenOptions = await getReligionsDialog();
      this.isLoading = false;
    },

    setPhone1(data) {
      this.user.userProfileData.userPhone1CountryCodeName =
        data.countryCodeName;
      this.user.userProfileData.userPhone1CountryCode = data.countryCode;
      this.user.userProfileData.userPhone1 = data.phoneNumber;
    },
    setPhone2(data) {
      this.user.userProfileData.userPhone2CountryCodeName =
        data.countryCodeName;
      this.user.userProfileData.userPhone2CountryCode = data.countryCode;
      this.user.userProfileData.userPhone2 = data.phoneNumber;
    },
    setPhone3(data) {
      this.user.userProfileData.userPhone3CountryCodeName =
        data.countryCodeName;
      this.user.userProfileData.userPhone3CountryCode = data.countryCode;
      this.user.userProfileData.userPhone3 = data.phoneNumber;
    },
    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        (this.$v.user.userNameAr.required &&
          this.$v.user.userNameAr.isValidUserNameAr) ||
        (this.$v.user.userNameEn.required &&
          this.$v.user.userNameEn.isValidUserNameEn &&
          this.$v.user.userEmail.isValidEmail)
      ) {
        this.$emit("submitForm");
      }
    },
    fullPathFileFromServer,
  },
  watch: {
    userTypeToken() {
      this.getEstablishmentRoleDialog();
    },
  },
  async created() {
    this.getEstablishmentRoleDialog();
    this.getMilitaryStatusTypesDialog();
    this.getEducationalStagesDialog();
    this.getSpecialtiesDialog();
    this.getNationalityDialog();
    this.getJobGradeTokenDialog();
    this.getSocialStatuseDialog();
    this.getInsuranceCompanyDialog();
    this.getBankDialog();
    this.getReligionsDialog();
    this.userAuthorizeToken = this.$store.getters.userAuthorizeToken;
    console.log(this.userAuthorizeToken);
  },
};
</script>
<style lang="scss">
.select-group-input-container {
  // display: flex;
  border: 1px solid var(--color-bg-secondary);
  border-start-start-radius: 5px;
  // border-start-end-radius: 30px;
  border-end-end-radius: 5px;
  border-end-start-radius: 30px;
  box-shadow: 0px 0px 10px var(--color-top);
  padding: 30px;
  & label {
    color: #86929e;
  }
}
.select-group-input {
  appearance: none; /* Disable default styles */
  -webkit-appearance: none; /* For older versions of Webkit browsers */
  -moz-appearance: none; /* For older versions of Firefox */
}
.select-group-input {
  height: 50px;
  width: 90%;
  display: block;
  margin: 5px auto;
  border-radius: 20px;
  padding: 1px 17px;
  border: 2px solid #39cbde;
  color: #86929e;
  option {
    outline: none !important;
    height: 50px !important;
  }
}
.select-group-option {
  margin: 1px;
  font-size: 12pt;
  &:hover {
    background-color: green;
    color: white;
  }
}
</style>
